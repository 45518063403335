import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import queryString from 'query-string'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faPencilAlt, faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import Container from "../components/layout/Container"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import Loading from "../components/Loading"
import MajorError from "../components/MajorError"

import SignPreviewImage from "../components/SignPreviewImage"
import SignDetails from "../components/SignDetails"

import { formatPrice } from "../utilities/utils"
import { postData } from '../services/server'
import HoverButton from "../components/buttons/HoverButton";
import ShipDate from "../components/ShipDate";
import Breadcrumb from "../components/layout/Breadcrumb";
import GoogleImage from "../components/GoogleImage"

import { CartContext } from '../state/CartContext';
import { colors, devices, storage } from "../settings";
import { isEmailAddress } from "../utilities/validation";
import { useCachedState } from "../hooks/useCachedState"
import Layout2Columns from "../components/layout/Layout2Columns";

const SignMenu = styled.ul`
  flex: 0;
  flex-basis: 270px;
  margin: 0px;
  padding: 0px 15px 15px 15px;
  list-style-type: none;
  border: 1px solid #ccc;
  border-radius: 5px;

  @media ${devices.mobile} {
    padding: 5px;
  }
`
const MenuItem = styled.li`
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 5px;
  text-align: center;

  border-color: ${props => props.color ? colors[props.color] : "#ccc"};
  color: ${props => props.color ? colors[props.color + "Text"] : "#666"};

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  a {
    color: ${props => props.color ? colors[props.color] : "inherit"};
    flex-basis: 100%;
  }

  > div {
    margin: 0px auto;

    > div {
      margin-top: 15px;

      > div {
        display: flex;

        svg {
          margin: 0px;
        }
        button {
          width: 60px;
        }
      }
    }
  }
`
const Price = styled.strong`
  font-size: 30px;
  font-weight: 400;
`

const SignPreview = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  min-height: 200px;

  @media ${devices.mobile} {
    min-height: auto;
  }
`
const Content = styled.div`
  border-top: 3px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-image: url(${storage.url}2020/tiles/grey3.jpg);
  padding-top: 25px;
  margin-top: 100px;
`
const SignTemplateIFrame = styled.iframe`
`
const Includes = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  > div {
    max-width: 150px;    
    padding: 5px 5px 0px 5px;
    border: 2px solid white;
    cursor: pointer;

    &.active {
      border-color: ${colors.orange};
    }
  }
`

class SignTemplateFrame extends React.PureComponent {
  render() {
    const {src,title} = this.props;

    return (
      <SignTemplateIFrame src={src} title={title} width="100%" height="100%" border="0" frameBorder="0" />
    );
  }
}

const SignTemplatePage = (props) => {
  const cart = useContext(CartContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);

  const [sign, setSign] = useState(null);

  const [items, setItems] = useCachedState("construction", []);
  const [includes, setIncludes] = useState([]);
  const [include, setInclude] = useState(null);

  const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")

  useEffect(
    () => {
        setLoading(true);

        const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")

        if (params.uid || params.id) {
          postData("/api/builder/signdata" + (params.id ? "?id=" + params.id:""), params,
              (result) => {
                  setSign(result);                
              },
              (error, status) => {
                if ((error.response && error.response.status == 404) || status == 404)
                  setError("Unable to locate the requested sign.");
                else
                  setError("An error occured while loading the sign.");
              },
              () => {
                  setLoading(false);
              }
          ); 
        }
        else setError("Invalid sign.")                 
    }, 
    []
  );

  useEffect(
    () => {
        if (sign && (!items || items.length === 0)) {
            postData("/api/content/type", { type: "construction"},
                (result) => {
                    setItems(result.filter(c => c.optional_ind == 0));                    
                },
                (error) => {
                    console.log(error);
                }
            );
        }      
    }, 
    [sign]
  );  

  function isValidIncludes(content) {
      const products = [];
      const parts = [];

      sign.groups.forEach((group) => {
          if (group.product) {
              products.push(group.product.id);                
              
              if (group.product.bundle_id) {
                  products.push(group.product.bundle_id);                
              }
          }

          for (const part of group.parts) {
              parts.push(part.id);
          }
      })

      if (content.product_id && products.indexOf(content.product_id) !== -1) {
          if (content.part_ids) {
              const partIds = content.part_ids.split(",");

              for (const partId of partIds) {
                  if (parts.indexOf(parseInt(partId)) !== -1) {
                      return true;
                  }
              }
          }
          else {
              return true;
          }
      }

      return false;
  }

  useEffect(
    () => {
        if (items && items.length && sign) {
          const validItems = [];

          items.map((item) => {
              const exists = validItems.find(x => x.name === item.name);
      
              if (!exists && isValidIncludes(item)) {            
                  validItems.push({
                      ...item,
                      icon: props.icon,
                      icon_url: "images/icons/" + item.icon_url,
                      image_url: "2020/construction/" + item.image_url
                  })
                  return true;
              }
          })

          setIncludes(validItems);
        }
    }, 
    [sign, items]
  );

  function onAddToCart() {
    setAdding(true);

    cart.addSignToCart(sign.id, (cart) => {
        if (cart) {
            navigate("/checkout/")
        }
        else {
          setError("An error occured adding the sign to your cart.")
        }

        setAdding(false);
    })
  }

  return (
    <>
      <SEO page="sign" title={sign ? "Sign Design #"+sign.id : "Sign Details"} />

      <Container>
        <Breadcrumb items={[
            { url: "/", title: "Home"},
            { url: "/account/signs", title: "My Signs"},
            { url: null, title: "Sign Details" }
          ]}
        />
      </Container>

        { loading ?
            <Loading label="Loading sign..." style={{margin: "50px" }} />
          : sign ?
            <>  
              <Container style={{marginBottom: "150px"}}>
                <PageHeader 
                      title={sign ? "Sign Design #" + sign.id : "Loading Sign..."} 
                    />

                <Layout2Columns left={60} right={40}>
                  <div>
                    <SignPreview>
                      <SignTemplateFrame 
                          src={process.env.GATSBY_BUILDER_BASE_URL + "?uid=" + sign.uid + "#/preview"} 
                          title={sign.title}
                      />

                      {false && <SignPreviewImage root src={sign.preview_url} responsive style={{ height: "auto", maxWidth: "100%", maxHeight: "400px", display: "block", margin: "10px auto" }} />}
                    </SignPreview>
                    <div>
                      Estimated Size: <strong>{sign.size}</strong>
                    </div>

                    <Includes>
                      <div onClick={() => setInclude(null)} className={!include ? "active" : ""}>
                        <SignPreviewImage root src={sign.preview_url} responsive />
                      </div>

                      {includes.map((content, index) => {
                          return (
                              <div key={index} onClick={() => setInclude(content)} className={include && include == content ? "active" : ""}>
                                  <GoogleImage 
                                      root
                                      src={content.image_url} 
                                      required 
                                      lazy
                                      alt={content.title} 
                                  />
                              </div>
                          )
                      })}                      
                    </Includes>
                  </div>
                  <div>
                    <SignMenu>
                      <MenuItem color="white" style={{ paddingRight: "0px"}}>
                        <Price>{ sign && formatPrice(sign.estimate) }</Price>
                        <HoverButton 
                            label="Buy"
                            color="green"
                            wide
                            style={{marginLeft: "15px"}}
                            busy={adding}
                            icon={faShoppingCart}
                            onClick={onAddToCart}
                        />
                      </MenuItem>
                      <li style={{padding: "10px"}}>
                        Total Price for complete sign ready to install.
                        <p>
                          Order Today Ships FREE: <ShipDate format="long" />
                        </p>
                      </li>
                      {sign.custom_ind == 0 && 
                        <MenuItem color="orange">
                          <Link to={"/build/?uid=" + sign.uid + "&template=1"}>
                            <FontAwesomeSvgIcon icon={faPencilAlt} />
                            Customize This Sign
                          </Link>
                        </MenuItem>
                      }
                    </SignMenu>
                  </div>
                </Layout2Columns>
              </Container>

              <Content>
              <Container>
                <SignDetails sign={sign} />
              </Container>
              </Content>
            </>
          :
            <div style={{ fontSize: "25px", textAlign: "center", margin: "50px", color: "#ccc"}}>
              Sign Not Found
            </div>
        }

        { (error && error != "Unable to locate the requested sign.") &&
            <MajorError 
              error={error} 
              log={error}
              onClose={() => setError(null)}
            />
        }
    </>
  )
}

export default SignTemplatePage
